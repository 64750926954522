import React from 'react';
import css from './SectionFeaturedListing.module.css';
import ProductCard from './ProductCard';
import { injectIntl } from '../../../util/reactIntl';
import { IconSpinner } from '../../../components';
import classNames from 'classnames';

function SectionFeaturedListing({ loading, error, products, intl }) {
  if (error) {
    return (
      <div>
        <span className={css.error}>
          {intl.formatMessage({ id: 'LandingPage.featuredListing.error' })}
        </span>
      </div>
    );
  }
  if (products?.length === 0) {
    return null;
  }
  if (loading) {
    return (
      <div className={css.loadingContainer}>
        <p>
          <IconSpinner style={{ style: { height: '50px', width: '50px' } }} />
        </p>
      </div>
    );
  }

  return (
    <div className={classNames(css.productsWrapper)}>
      {products?.length > 0 ? (
        <h3 className={`text-left text-3xl font-bold mt-6 ${css.allColumns}`}>
          {intl.formatMessage({ id: 'LandingPage.featuredListingTitle' })}
        </h3>
      ) : null}
      <div className={css.container}>
        {(products ?? []).map(p => (
          <ProductCard
            id={p.id.uuid}
            key={p.id.uuid}
            metadata={p?.attributes?.metadata}
            title={p?.attributes?.title}
            description={p?.attributes?.description}
            publicData={p?.attributes?.publicData}
            images={p?.images}
            createdAt={p?.attributes?.createdAt}
            intl={intl}
            price={p?.attributes?.price}
            authorName={p?.author?.attributes?.profile?.displayName}
          />
        ))}
      </div>
    </div>
  );
}

export default injectIntl(SectionFeaturedListing);
